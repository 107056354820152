:root {
  --font-family: 'Source Sans Pro', 'Roboto', sans-serif;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

.auth-page {
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
}

.auth-page a {
  margin: 1rem auto;
  font-size: 0;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.75) 0 0 5px -1px;
  animation: 1s ease-in-out infinite alternate wobble;
}

.auth-page a.telegram {
  background: #2aabee linear-gradient(90deg, #2aabee 0%, #229ed9 100%);
}

.auth-page a.opensatnav {
  padding: 1rem;
  background: #bdbdbd;
}

@keyframes wobble {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.05);
  }
}

.google-map {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  user-select: none;
}

.gm-control-active {
  border-radius: 20px !important;
  outline: none;
}

.gm-bundled-control > div > div {
  border-radius: 20px !important;
}

.overlay-marker {
  --background-color: black;
  --border-color: white;
  --label-color: black;
  --note-color: #5383ec;
  box-sizing: border-box;
  position: absolute;
  margin: -10px -10px;
  width: 20px;
  height: 20px;
  user-select: none;
  background-color: var(--background-color);
  background-origin: content-box;
  background-size: contain;
  border: 4px solid var(--border-color);
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.75) 0 0 5px -1px;
}

.overlay-marker.cyan {
  --background-color: #00b8d4;
  --border-color: #b2ebf2;
}
.overlay-marker.purple {
  --background-color: #aa00ff;
  --border-color: #e1bee7;
}
.overlay-marker.red {
  --background-color: #d50000;
  --border-color: #ffcdd2;
}
.overlay-marker.own {
  --background-color: #5383ec;
  --border-color: white;
  z-index: 5;
}

.overlay-marker[data-heading] {
  border: 4px solid var(--background-color);
  box-shadow: none;
}

.overlay-marker[data-heading]::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  margin: -4px -4px;
  width: 20px;
  height: 20px;
  background: transparent;
  border: 4px solid var(--border-color);
  border-radius: 50%;
  border-top-right-radius: 0;
  box-shadow: rgba(0, 0, 0, 0.75) 0 0 5px -1px;
  transform: rotate(var(--heading));
  transition: transform .5s;
}

.overlay-marker label {
  position: absolute;
  top: -10px;
  left: 10px;
  display: block;
  padding: 18px 6px 6px 6px;
  width: 30px;
  color: var(--note-color);
  font-size: 8px;
  font-family: var(--font-family);
  text-shadow: white 0 0 1px;
  cursor: inherit;
}

.overlay-marker label::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  padding: 6px;
  max-width: 60px;
  overflow: hidden;
  font-size: 11px;
  font-weight: bolder;
  text-overflow: ellipsis;
  text-shadow: black 0 0 7px;
  white-space: nowrap;
  -webkit-text-stroke: 5px var(--border-color);
}

.overlay-marker label::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  padding: 6px;
  max-width: 60px;
  overflow: hidden;
  color: var(--label-color);
  font-size: 11px;
  font-weight: bolder;
  text-overflow: ellipsis;
  text-shadow: none;
  white-space: nowrap;
}

/* WORKAROUND: iOS Webkit incorrectly applies shadow on stroked text. */
@media screen and (-webkit-min-device-pixel-ratio: 0) { _::-webkit-full-page-media, _:future, :root,
  .overlay-marker label::before {
    text-shadow: rgba(0, 0, 0, 0.3) 0 0 1px;
  }
}

.cluster {
  border: 4px solid white;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: rgba(0, 0, 0, 0.75) 0 0 5px -1px;
  pointer-events: none;
}

.cluster div {
  outline: none;
}

.cluster span {
  font-weight: bolder;
  padding: 2px 3px 1px 3px;
  color: black;
  background: white;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.75) 0 0 5px -1px;
}

.cluster img {
  display: none;
}

.overview .overlay-marker:not(.own):not(:hover) {
  margin: -5px -5px;
  width: 10px;
  height: 10px;
  border-width: 2px;
}

.overview .cluster {
  border-width: 2px;
}

.overview .overlay-marker:not(.own):not(:hover)[data-heading]::before {
  margin: -2px -2px;
  width: 10px;
  height: 10px;
  border-width: 2px;
}

.overview .overlay-marker:not(:hover) label {
  display: none;
}

.inactive .inset {
  box-shadow: inset 0 0 100px #d50000;
}
